import { MAINTAIN_PERMISSION, STERILIZATION_SPECIFICATIONS_REPORTS } from "@kraftheinz/common";
import { n as normalizeComponent } from "./index.js";
import "lodash";
var render$1 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", { staticClass: "printed-log p-5" }, [_c("div", { staticClass: "p-3" }, [_c("a-row", [_c("a-col", { attrs: { "span": 12 } }, [_c("div", { staticClass: "pr-2" }, [_c("label", [_vm._v("Item Code")]), _c("a-auto-complete", { staticClass: "mt-3", staticStyle: { "width": "100%" }, attrs: { "option-label-prop": "selectedItemCode", "placeholder": "Please enter code", "disabled": !_vm.hasMaintainPermission }, on: { "select": _vm.onSelect, "search": _vm.setFilterParams }, model: { value: _vm.selectedItemCode, callback: function($$v) {
    _vm.selectedItemCode = $$v;
  }, expression: "selectedItemCode" } }, [_c("template", { slot: "dataSource" }, [_vm.dataSource.length > 0 ? _c("a-select-option", { key: "label" }, [_c("div", { staticClass: "d-flex" }, [_c("div", { staticStyle: { "width": "20%" } }, [_vm._v("Item Code")]), _c("div", { staticStyle: { "width": "25%" } }, [_vm._v("Proc Code")]), _c("div", { staticStyle: { "width": "25%" } }, [_vm._v("Fg Item Code")]), _c("div", { staticStyle: { "width": "30%" } }, [_vm._v("Description")])])]) : _vm._e(), _vm._l(_vm.dataSource, function(opt) {
    return _c("a-select-option", { key: opt.id, attrs: { "value": JSON.stringify(opt) } }, [_c("div", { staticClass: "d-flex" }, [_c("div", { staticStyle: { "width": "20%" } }, [_vm._v(_vm._s(opt.itemCode))]), _c("div", { staticStyle: { "width": "25%" } }, [_vm._v(_vm._s(opt.procCode))]), _c("div", { staticStyle: { "width": "25%" } }, [_vm._v(_vm._s(opt.fgItemCode))]), _c("div", { staticStyle: { "width": "30%" } }, [_vm._v(_vm._s(opt.description))])])]);
  })], 2)], 2)], 1)]), _c("a-col", { attrs: { "span": 12 } }, [_c("div", { staticClass: "pl-2" }, [_c("label", { staticClass: "mb-3" }, [_vm._v("Date")]), _c("a-date-picker", { staticClass: "mt-3", staticStyle: { "width": "100%" }, attrs: { "value": _vm.selectionObj.datePrinted, "disabled": !_vm.hasMaintainPermission }, on: { "change": function(val) {
    return _vm.setValue(val, "datePrinted");
  } } })], 1)])], 1), _c("div", { staticClass: "d-flex justify-content-end border-top mt-3 pt-3" }, [_c("a-button", { staticClass: "ml-3", attrs: { "type": "default", "disabled": !_vm.hasMaintainPermission }, on: { "click": _vm.reset } }, [_vm._v("Clear All")]), _vm.hasMaintainPermission ? _c("a-button", { staticClass: "ml-3", attrs: { "disabled": !_vm.selectionObj.itemCode || !_vm.selectionObj.datePrinted, "loading": _vm.showPreviewLoader, "type": "primary" }, on: { "click": _vm.submit } }, [_vm._v("Preview")]) : _vm._e()], 1)], 1)]);
};
var staticRenderFns$1 = [];
var ReportsPrintedLog_vue_vue_type_style_index_0_scoped_true_lang = "";
const __vue2_script$1 = {
  name: "ReportsPrintedLog",
  inject: ["apiUrl", "crud", "can"],
  data() {
    return {
      selectedItemCode: null,
      dataSource: [],
      selectionObj: {
        itemCode: "",
        datePrinted: null
      },
      previewLoading: false,
      permissions: {
        maintain: MAINTAIN_PERMISSION
      }
    };
  },
  computed: {
    showPreviewLoader() {
      return this.previewLoading;
    },
    hasMaintainPermission() {
      return this.can(this.permissions.maintain);
    }
  },
  mounted() {
    this.crud.deleteFilter("IsInactive");
  },
  methods: {
    setFilterParams(searchText) {
      if (!searchText)
        return;
      this.crud.setFilter("ItemCode", {
        operator: "contains",
        value: searchText
      });
      this.crud.getFilterQuery();
      this.getSuggestionList();
    },
    onSelect(selectedItemObj) {
      try {
        this.selectedItemCode = JSON.parse(selectedItemObj).itemCode;
        this.setValue(this.selectedItemCode, "itemCode");
      } catch (e) {
        this.$notification["error"]({
          message: "Errors encountered. Please try again!"
        });
      }
    },
    setValue(val, key) {
      this.selectionObj[key] = val;
    },
    async getSuggestionList() {
      const data = (await this.crud.fetchList(this.apiUrl + `/sterilization/common/item-masters`)).data;
      this.dataSource = data.map((item, idx) => {
        return Object.assign({}, item, { id: idx + "" });
      });
    },
    submit() {
      const data = {
        report: "PrintedLog",
        reportType: "steri",
        reportParams: {
          BSItemCode: this.selectionObj.itemCode,
          DatePrinted: this.$moment(this.selectionObj.datePrinted).format("YYYY-MM-DD")
        }
      };
      this.previewLoading = true;
      this.axios.post(`${this.apiUrl}/identities/reports`, data).then((res) => {
        if (!res || !res.data || !res.data.reportViewerUri) {
          this.$notification["error"]({
            message: "Report not found!"
          });
        } else {
          window.open(res.data.reportViewerUri, "_blank");
        }
      }, (err) => {
        this.$notification["error"]({
          message: err.message || "Errors!"
        });
      }).finally(() => this.previewLoading = false);
    },
    reset() {
      this.selectedItemCode = null;
      this.dataSource = [];
      this.selectionObj = {
        itemCode: "",
        datePrinted: null
      };
      this.previewLoading = false;
    }
  }
};
const __cssModules$1 = {};
var __component__$1 = /* @__PURE__ */ normalizeComponent(__vue2_script$1, render$1, staticRenderFns$1, false, __vue2_injectStyles$1, "1cf1f484", null, null);
function __vue2_injectStyles$1(context) {
  for (let o in __cssModules$1) {
    this[o] = __cssModules$1[o];
  }
}
var ReportsPrintedLog = /* @__PURE__ */ function() {
  return __component__$1.exports;
}();
var render = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", [_c("bread-crumb", { attrs: { "items": _vm.itemsMenu } }), _c("resource", { attrs: { "page": _vm.page, "resource-id-name": "itemCode", "edit": _vm.ReportsPrintedLog, "redirect-route": "/sterilization-specifications/reports", "name": "sterilization.reports-printed-log", "api-url": _vm.apiUrl } })], 1);
};
var staticRenderFns = [];
const __vue2_script = {
  name: "ReportsPrintedLog",
  data() {
    return {
      ReportsPrintedLog,
      page: STERILIZATION_SPECIFICATIONS_REPORTS,
      apiUrl: "#{VUE_APP_API_URL}#",
      itemsMenu: [
        {
          key: "reports",
          title: "Reports",
          path: "/sterilization-specifications/reports"
        },
        {
          key: "ReportsPrintedLog",
          title: "Reports Printed Log",
          path: "/sterilization-specifications/reports/reports-printed-log"
        }
      ]
    };
  }
};
const __cssModules = {};
var __component__ = /* @__PURE__ */ normalizeComponent(__vue2_script, render, staticRenderFns, false, __vue2_injectStyles, null, null, null);
function __vue2_injectStyles(context) {
  for (let o in __cssModules) {
    this[o] = __cssModules[o];
  }
}
var index = /* @__PURE__ */ function() {
  return __component__.exports;
}();
export { index as default };
